<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<h3>Framework access report</h3>
	<img srcset="/docimages/framework_update.png 2x" class="k-help-img float-right">
	<p class="k-no-clear">
		System Admin can access the <strong>Framework Access Report</strong>, from the hamburger menu in the top right corner of their screen to view all frameworks. 
		<ol>
			<li>A System or Framework Admin may see additional options based on their permissions. See the User Guide for <span v-html="link('user_roles_admin', 'Managing User Roles')"></span> for more information.</li>
		</ol>
		<br>
		The <strong>Framework Access Report</strong> is typically used by <span v-html="app_name"/> leaders to monitor the trend in overall access of the website or the API calls.
		
		<ul>
			<li>The report has two filters to toggle between usage data for the Web Application or the CASE API.</li>
			<li>There is also a SEARCH field to narrow down the list of frameworks visible in the table below the usage graph. </li>
			<li>Click on any column headers, to sort the table as needed.</li>
			<img srcset="/docimages/framework_access.png 1x" class="k-help-img block">
			<p>The <strong>Framework Access Report</strong> can also be generated by a Framework or System Admin for one specific framework:
				<ol>
					<li>Select a framework</li>
					<li>Navigate to the <v-icon medium>fa-solid fa-ellipsis-vertical</v-icon> KEBAB MENU of the framework to select <strong>Framework Access Report</strong></li>
					<li>Note an additional filter is available for the <img srcset="/docimages/look_back_dropdown.png 4x" class="k-help-img"> <strong>Days to Look Back (1-7)</strong></li>
				</ol>
			</p>
		</ul>
	</p>


</div></template>

<script>
	import HelpMixin from './HelpMixin'
	export default {
		mixins: [HelpMixin],
	}
</script>

<style lang="scss">
</style>
	
	